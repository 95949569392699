.contact-form {
  /* padding: 60px 0 0; */
}

.contact-form h4 {
  color: #000000;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 12px;
}

.contact-form p {
  color: rgba(0, 0, 0, 0.75);
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
}

.form-input {
  margin: 10px 0;
}

.form-input label {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-bottom: 5px;
}

.form-input input,
.form-input select {
  height: 44px;
}

.form-input input,
.form-input select,
.form-input textarea {
  background: #ffffff;
  border: none;
  width: 100%;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 400;
  color: black;
}

.form-input .custom-checkbox {
  margin: 0 0 0 0;
  display: flex;
  margin-right: 20px;
  align-items: center;
  position: relative;
}

.custom-checkbox input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
}

.custom-checkbox span {
  width: 18px;
  height: 18px;
  border: 2px solid #d3d3d3;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  font-size: 0;
  transition: all 0.5s;
}

.custom-checkbox p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 0;
}

.custom-checkbox input:checked + span {
  background: #043289;
  border-color: #043289;
  font-size: 22px;
  color: #fff;
}

.form-input textarea {
  width: 100%;
  padding: 15px;
  border: none;
  resize: none;
}

.contact-info {
  padding: 20px;
  list-style: none;
  margin: 0;
  background: #fff;
}

.contact-info li a {
  color: #043289;
  display: flex;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  -webkit-text-stroke: 0.2px;
}

.contact-info li a svg {
  margin-right: 10px;
  font-size: 22px;
}

/* General select styling */
select {
  -webkit-appearance: none; /* Remove default dropdown arrow */
  -moz-appearance: none; /* Remove default dropdown arrow in Firefox */
  appearance: none; /* Remove default dropdown arrow in modern browsers */
  background: #f8f8f8; /* Light background color for the select */
  border: 1px solid #ccc; /* Border styling */
  border-radius: 4px; /* Rounded corners */
  padding: 10px; /* Padding inside the select */
  width: 100%; /* Full-width select */
  font-size: 16px; /* Adjust font size */
  line-height: 1.5; /* Adjust line height */
  color: #333; /* Text color */
}

/* Style for select element to ensure consistent height */
select::-ms-expand {
  display: none; /* Hide default arrow in Internet Explorer */
}

/* Custom arrow for select */
select::after {
  content: "▼"; /* Unicode arrow character */
  position: absolute;
  right: 15px;
  pointer-events: none; /* Prevent arrow from intercepting clicks */
  font-size: 10px;
}

/* Specific styling for Safari */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    select {
      padding-right: 30px; /* Extra padding to accommodate custom arrow */
    }
  }
}

.contact-info li .btn-blue {
  color: #fff;
  margin: 45px 0 0;
  letter-spacing: 1px;
  padding: 10px;
}
@media (max-width: 991px) {
  .contact-info {
    margin-top: 30px;
  }
}
@media (max-width: 575px) {
  .form-input label {
    font-size: 14px;
  }
}
